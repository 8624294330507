import React from "react"
import { trySetGaStatus } from "../utils/local-storage"
import { tryGetGaStatus } from "../utils/local-storage"

class GaConsent extends React.Component {
  state = {
    gaStatus: null,
  }

  componentDidMount() {
    let gaDisabled = tryGetGaStatus()
    this.setState({
      gaStatus: gaDisabled === "disabled" ? "disabled" : "enabled",
    })
  }

  giveConsent = (e, allowGa) => {
    e.preventDefault()
    let gaStatus = allowGa ? "enabled" : "disabled"
    this.setState({ gaStatus: gaStatus })
    trySetGaStatus(gaStatus)
  }

  render() {
    return (
      <div>
        <div className="buttons are-small has-addons">
          <button
            className={`button ${
              this.state.gaStatus === "disabled" ? "is-danger is-selected" : ""
            }`}
            onClick={e => this.giveConsent(e, false)}
          >
            No
          </button>
          <button
            className={`button ${
              this.state.gaStatus === "enabled" ? "is-success is-selected" : ""
            }`}
            onClick={e => this.giveConsent(e, true)}
          >
            Yes
          </button>
        </div>
      </div>
    )
  }
}

export default GaConsent
