import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GaConsent from "../components/ga-consent"
import Hero from "../components/hero"

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy policy" />

    <div className="container">
      <Hero
        title="Privacy policy"
        subtitle="The steps we took to ensure your privacy on our website"
        subtitleSize="6"
      />

      <section className="section privacy-item mt-3">
        <p>
          At Codestack, we take your privacy and protection of personal data{" "}
          <strong>very</strong> seriously.
        </p>
        <p>
          This privacy policy will explain what data we collect and how we made
          sure that everything is put in place to safeguard your privacy.
        </p>
      </section>
      <section className="section privacy-item">
        <p>
          <strong>What data do we collect?</strong>
        </p>

        <div className="content privacy-item">
          <ol type="1">
            <li>Fully anonymized visitor statistics using Google Analytics</li>
            <li>Form data collected by Getform.io</li>
          </ol>
        </div>
      </section>
      <section className="section privacy-item">
        <p>
          <strong>
            How we configured Google Analytics to be fully anonymous and GDPR
            compliant
          </strong>
        </p>
        <div className="content">
          <ul>
            <li>
              We use a Google Analytics cookie that will store a random
              identifier for a visitor
            </li>
            <li>
              We signed a Data Processing Amendment with Google, accepted on
              April 19, 2020
            </li>
            <li>
              The last octet of the IP address being communicated with Google
              has been omitted
            </li>
            <li>
              ALL marketing or ad related features of Google Analytics were
              disabled
            </li>
            <li>
              We do NOT use any other Google services in combination with Google
              analytics cookies
            </li>
          </ul>
        </div>
      </section>
      <section className="section privacy-item">
        <p>
          If you still don't feel reassured by all the measures we put in place,
          you can opt-out of all Google Analytics interactions completely,
          below.
        </p>
        <div className="consent-box">
          <p>Allow anonymous visitor statistics on this page?</p>
          <GaConsent />
        </div>
      </section>

      <section className="section privacy-item">
        <p>
          <strong>
            How we use the data you provide to us using the contact form
          </strong>
        </p>
        <p className="privacy-item-content">
          When using the contact form, data you provide will be sent and stored
          using Getform.io.{" "}
        </p>
        <p>
          All the data you submit to Getform is strictly protected with their
          GDPR compatible data protection policies.
        </p>
      </section>
      <section className="section privacy-item">
        <p>
          <strong>What will we use this data for?</strong>
        </p>
        <div className="content">
          <ul>
            <li>To have a view on the number of visitors on the website</li>
            <li>
              To get in touch with your after sending us a message using the
              contact form
            </li>
          </ul>
        </div>
      </section>
      <section className="section privacy-item">
        <p>
          <strong>Marketing</strong>
        </p>
        <p className="privacy-item-content">
          The data you provide us will never be used for marketing purposes.
        </p>
      </section>

      <section className="section privacy-item">
        <p>
          <strong>What types of cookies do we use?</strong>
        </p>
        <p className="privacy-item-content">
          We only use a cookie for Google Analytics. The cookie contains a
          random identifier to link page visits to a single visitor in a single
          browser session. This identifier is completely random and cannot in
          any way be used to personally identify a visitor. Furthermore, the
          expiration value of the cookie has been set to 0 (zero) seconds. That
          means the cookie turns into a session based cookie and expires once
          the current browser session ends.
        </p>
      </section>

      <section className="section privacy-item">
        <p>
          <strong>How to contact us?</strong>
        </p>
        <p className="privacy-item-content">
          If you have any questions about our privacy policy, the data we hold
          on you, or you would like to exercise one of your data protection
          rights, please do not hesitate to contact us.
        </p>
        <br />
        <p>
          Use the contact form or contact us directly at{" "}
          <a href="mailto:info@codestack.be">info@codestack.be</a>
        </p>
      </section>
    </div>
  </Layout>
)

export default PrivacyPolicy
